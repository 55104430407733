@import '../../../../../../styles/shared';

.title {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
  font-family: var(--hub-module-heading-font-family);

  @include mq($from: desktop) {
    margin-bottom: 30px;
  }
}

.text {
  font-size: 16px;
  line-height: 26px;
}

.ctaButton {
  margin-top: 16px;

  @include mq($from: desktop) {
    margin-top: 34px;
    padding: 8px 38px;
    min-height: auto;
    display: inline-flex;
  }
}
